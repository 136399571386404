import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import getSubDomain from '@/utils/getSubDomain';
import { getEnv } from '@/utils/importMeta';
import { MeState } from '@/services/store/store';
import amplitude from 'amplitude-js';


const AMPLITUDE_API_KEY = getEnv('VITE_AMPLITUDE_API_KEY');

const Tracking:FC = () => {
  const location = useLocation();
  const me = useRecoilValue(MeState);

  useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_API_KEY,me?.attributes?._id?.toString(),{
      apiEndpoint: 'api.eu.amplitude.com'
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if(!me?.attributes?.role || !AMPLITUDE_API_KEY) {
      return;
    }

    amplitude.getInstance().setUserId(me?.attributes?._id?.toString() ?? null);
    amplitude.getInstance().setUserProperties({
      role: me.attributes.role,
      institution: getSubDomain(),
    });
  },[me]);

  useEffect(
    () => {

      if(!AMPLITUDE_API_KEY) {
        return;
      }

      amplitude.getInstance().logEvent('Page View', {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        page: document.querySelector('title')!.innerText.trim()
      });
    },
    [location.pathname]
  );

  return null;
};

export default Tracking;
