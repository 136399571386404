import { useEffect } from 'react';
import { Response } from '@/services/request/useRequest';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { MeState } from '@/services/store/store';

import { ME, MeItem, MeUser } from '@/packages/back-end/user';
import useJsonAPIRequest, { UseJsonApiRequestProps } from '@/services/request/useJsonAPIRequest';
import { isJwtSimultaneousLogin } from '@/services/request/utils';
import { useLocation } from 'react-router-dom';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import useRequestError from '@/utils/hooks/useRequestError';
import useSetLocale from '@/utils/useSetLocale';


const isIgnoredRoutes = (pathname : string) : boolean => {

  return [
    '/auth/pro_sante_connect/login/callback',
    '/auth/pro_sante_connect/signup/callback',
  ].includes(pathname);

};

export type UseMe = (args?: Omit<UseJsonApiRequestProps, 'url'>) => Response<unknown, MeItem> & {
  addModule: (module : string) => void;
};

const useMe: UseMe = (props = {}) => {
  const setMe = useSetRecoilState(MeState);

  const me = useRecoilValue(MeState);

  const { openModal } = useRequestError();
  const { pathname } = useLocation();

  const { setLocale } = useSetLocale();

  const { called, data, ...rest } = useJsonAPIRequest<MeItem>({
    ...props,
    include: ['services', 'institutions', 'phones'],
    url: ME()
  });

  useEffect(
    () => {
      if (!called) {
        return;
      }

      const refreshToken = storage.getItem(StorageKeys.REFRESH_TOKEN);

      if (rest?.error && isJwtSimultaneousLogin(rest.error?.errorKey) && !isIgnoredRoutes(pathname) && refreshToken) {
        openModal('auth');
        return;
      }

      const me = data?.data as MeUser;

      setMe(me);

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data,called,rest?.error]
  );

  useEffect(() => {

    if(!data?.data) {
      return;
    }

    const me = data?.data as MeUser;
    setLocale(me.attributes?.locale);

  }, [data?.data,setLocale]);


  const addModule = (module : string) => {

    if(!me) {
      return;
    }

    const enabledModules : {[key : string] : boolean } = { ...me.attributes.enabledModules } || {};
    enabledModules[module] = true;


    const newMe = {
      ...me,
      attributes: {
        ...me.attributes,
        enabledModules
      }
    };

    setMe(newMe);

  };

  return {
    ...rest,
    called,
    addModule,
    data
  };
};


export default useMe;
